const DocMenuConfig = [
  {
    pages: [
      {
        heading: "home",
        route: "/home",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
 ];

export default DocMenuConfig;
