
import { defineComponent, ref } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const panelCookie = ref();
    const store = useStore();
    const router = useRouter();

    function removeCookie() {
      panelCookie.value?.removeCookie();
    }

    function signOut() {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    }

    const cookieClickedDecline = () => {
      removeCookie();
      signOut();
    };
    return {
      footerWidthFluid,
      cookieClickedDecline,
      panelCookie,
    };
  },
});
